import styled from 'styled-components';
import colors from '../../configs/colors';
import fontSizes from '../../configs/fontSizes'; 

const TASK_HEIGHT = 50;
const TASK_STATUS_HEIGHT = 25;
const TASK_STATUS_WIDTH = 7;
const TASK_NAME_MARGIN = 10;

const TaskDiv = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: ${TASK_HEIGHT}px;
    border-bottom: 1px solid ${colors.border};
    cursor: pointer;
`;

const TaskInnerDiv = styled.div`
    display: flex;
    align-items: center;
    width: calc(100%-${TASK_NAME_MARGIN}px);
    padding-left: ${TASK_NAME_MARGIN}px;
    height: ${TASK_STATUS_HEIGHT}px;
    font-size: ${fontSizes.regular}px;
    border-left: ${TASK_STATUS_WIDTH}px solid ${props => props.isCompleted ? colors.primary : colors.inactive};
`;

export { TaskDiv, TaskInnerDiv }