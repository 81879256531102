import { TaskDiv, TaskInnerDiv } from "./index.style";

function Task(props) {
    return (
        <TaskDiv>
            <TaskInnerDiv isCompleted={props.isCompleted}>
                {props.name}
            </TaskInnerDiv>
        </TaskDiv>
    );
}

export default Task;