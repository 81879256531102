import styled from 'styled-components';

export const UserGuideOverlay = styled.div`
    position: fixed; /* Use fixed to position relative to the viewport */
    bottom: 0;       /* Align to the bottom */
    left: 0;         /* Align to the left */
    width: 25%;    /* Adjust the width to fit your design */
    height: 40%;   /* Adjust the height as necessary */
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
    border: 1px solid rgba(0, 0, 0, 0.1); /* Slight border */
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    pointer-events: none; /* Allows interaction with elements underneath */
`;

export const UserGuideContent = styled.div`
  position: relative;
  padding: 15px;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const Title = styled.h2`
  margin-bottom: 15px;
  color: black; /* Keep text color visible */
`;

export const Instruction = styled.div`
  margin-top: 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
`;

export const KeyVisual = styled.div`
  display: inline-block;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 10px;
  font-family: monospace;
  font-size: 16px;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;
