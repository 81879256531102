import { CustomSelectComponent, CustomSelectDiv } from "./index.style";
import React from 'react';

function CustomSelect(props) {
  const hasCategories = Array.isArray(props.options) && props.options.some(option => option.category);

  if (hasCategories) {
    return (
      <CustomSelectDiv style={props.outerStyle}>
        <CustomSelectComponent onChange={props.onChange} style={props.style} value={props.value}>
          {props.options.map((category, categoryIndex) => (
            <React.Fragment key={categoryIndex}>
              <optgroup label={category.category}>
                {category.options.map((option, optionIndex) => (
                  <option key={`${categoryIndex}-${optionIndex}`} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </optgroup>
            </React.Fragment>
          ))}
        </CustomSelectComponent>
      </CustomSelectDiv>
    );
  }

  return (
    <CustomSelectDiv style={props.outerStyle}>
      <CustomSelectComponent onChange={props.onChange} style={props.style} value={props.value}>
        {props.options.map((option, index) => (
          <option key={index} value={option.value}>{option.text}</option>
        ))}
      </CustomSelectComponent>
    </CustomSelectDiv>
  );
}

export default CustomSelect;