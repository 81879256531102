import styled from 'styled-components';
import colors from '../../configs/colors';
import fontSizes from '../../configs/fontSizes'; 
import constants from '../../configs/constants';

const CustomTextAreaComponent = styled.textarea`
    outline: none;
    border-radius: ${constants.borderRadius}px;
    border-color: ${colors.border};
    font-size: ${fontSizes.regular}px;
    padding: 15px 20px;
    resize: none;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
`;

export { CustomTextAreaComponent }