import styled, { keyframes } from 'styled-components';

// Define the spin animation
const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

// Styled div for the spinner
export const SpinnerWrapper = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);  // Light border
    border-left-color: #09f;  // Color for the spinning part
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: ${spin} 1s linear infinite;  // Spin with the animation
    display: inline-block;
`;
