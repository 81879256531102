import styled, { keyframes, css} from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

export const Timer = styled.div`
    font-weight: bold;
    font-size: 1.2em;
    margin-right: 15px;
`;
const wave = keyframes`
    0% { height: 10px; }
    50% { height: 30px; }
    100% { height: 10px; }
`;

export const WaveForm = styled.div`
    display: flex;
    align-items: center;
    height: 40px;

    &::before, &::after {
        content: '';
        width: 3px;
        height: 20px;
        background-color: #007bff;
        margin: 0 3px;
        ${props => props.isAnimating && css`
            animation: ${wave} 0.5s ease-in-out infinite;
        `}
    }

    &::after {
        animation-delay: 0.25s;
    }
`;