import React from 'react';
import { UserGuideOverlay, UserGuideContent, Title, ButtonWrapper, KeyVisual, Instruction } from './index.style';

const UserGuidePopup = ({ onClose }) => {
  return (
    <UserGuideOverlay>
      <UserGuideContent>
        <ButtonWrapper>
        </ButtonWrapper>   
        <Instruction>
          <KeyVisual>Space</KeyVisual> = Record Audio
        </Instruction>
        <Instruction>
          <KeyVisual>→</KeyVisual> = Move Step to Right
        </Instruction>
        <Instruction>
          <KeyVisual>←</KeyVisual> = Move Step to Left
        </Instruction>
        <Instruction>
          <KeyVisual>R</KeyVisual> = Record Annotation
        </Instruction>

      </UserGuideContent>
    </UserGuideOverlay>
  );
};

export default UserGuidePopup;
