import React from 'react';
import { GuidanceOverlay, GuidanceContent, Title, GuidanceList, GuidanceItem, ButtonWrapper } from './index.style';

const GuidanceListPopup = ({ guidanceList, onClose }) => {
  // split the guidance list into an array
  const findings = guidanceList.split('\n').filter(item => item.trim().startsWith('-'));

  return (
    <GuidanceOverlay>
      <GuidanceContent>
        <ButtonWrapper>
        </ButtonWrapper>
        <Title>Guidance List</Title>
        <GuidanceList>
          {findings.map((finding, index) => (
            <GuidanceItem key={index}>{finding.trim().substring(2)}</GuidanceItem>
          ))}
        </GuidanceList>
      </GuidanceContent>
    </GuidanceOverlay>
  );
};

export default GuidanceListPopup;
