import styled from 'styled-components';
import colors from '../../configs/colors';
import fontSizes from '../../configs/fontSizes'; 

const TASK_LABEL_MARGIN_BOTTOM = 2;

const TaskTitleContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
`;

const TaskLabel = styled.div`
    font-size: ${fontSizes.regular}px;
    color: ${colors.greyText};
    margin-bottom: ${TASK_LABEL_MARGIN_BOTTOM}px;
`;

const TaskName = styled.div`
    font-size: ${fontSizes.header}px;
    font-weight: bold;
`;

export { TaskTitleContainer, TaskLabel, TaskName }