import { TaskTitleContainer, TaskLabel, TaskName } from "./index.style";

function TaskTitle() {
    return (
        <TaskTitleContainer>
            <TaskLabel>TASK</TaskLabel>
            <TaskName>INPUT CHECK</TaskName>
        </TaskTitleContainer>
    );
}

export default TaskTitle;