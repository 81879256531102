import logo from './assets/logo.svg';
import './App.css';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import Main from './views/Main';
import Login from './views/Login';
import { StepsProvider } from "./contexts/StepsContext";

// All the routes are defined here
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <StepsProvider>
        <Main />,
      </StepsProvider>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
