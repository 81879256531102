import React from 'react';
import { CustomButtonComponent } from "./index.style";
import * as Icons from "react-icons/fa";

function CustomButton(props) {
    const { iconName, spinningIcon, text, ...otherProps } = props;
    const IconComponent = Icons[iconName];

    return (
        <CustomButtonComponent {...otherProps}>
            {text}
            {IconComponent ? (
                <IconComponent 
                    style={{ marginLeft: 5 }} 
                    className={spinningIcon ? 'spinning' : ''}
                />
            ) : null}
        </CustomButtonComponent>
    );
}

export default CustomButton;
