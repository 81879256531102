import Navbar from "../Navbar";
import { WrapperDiv } from "./index.style";

function Wrapper(props) {
    return (
        <WrapperDiv {...props} id="wrapper">
            {props.children}
        </WrapperDiv>
    );
}

export default Wrapper;