import styled, { keyframes } from 'styled-components';
import colors from '../../configs/colors';
import fontSizes from '../../configs/fontSizes'; 
import constants from '../../configs/constants';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const CustomButtonComponent = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;

    background-color: ${props => props.isRecording ? 'red' : colors.primary};
    color: white;
    font-size: ${fontSizes.regular}px;
    border-radius: ${constants.borderRadius}px;
    outline: none;
    border: none;
    padding: 8px 15px;

    &:hover {
        cursor: pointer;
        filter: brightness(90%);
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    // Spinning animation for icons
    .spinning {
        animation: ${spin} 2s linear infinite;
    }
`;

export { CustomButtonComponent };
