import styled from 'styled-components';

export const TranscriptContainer = styled.div`
  position: relative;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
`;

export const Timestamp = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  color: #007bff;
`;

export const TranscriptText = styled.div`
  margin-bottom: 30px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
`;
