import React from 'react';
import { SpinnerWrapper } from './index.style';


// Spinner functional component
const Spinner = () => {
    return <SpinnerWrapper />;
};

export default Spinner;
