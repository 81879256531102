const FONT_SIZES = {
    smallest: 10,
    small: 12,
    regular: 14,
    bigger: 16,
    header: 18
}

const LINEHEIGHT_SCALING_FACTOR = 1.45;

export default {
    ...FONT_SIZES,

    // Line heights for different font sizes
    lineHeight: {
        smallest: FONT_SIZES.smallest * LINEHEIGHT_SCALING_FACTOR,
        small: FONT_SIZES.small * LINEHEIGHT_SCALING_FACTOR,
        regular: FONT_SIZES.regular * LINEHEIGHT_SCALING_FACTOR,
        bigger: FONT_SIZES.bigger * LINEHEIGHT_SCALING_FACTOR,
        header: FONT_SIZES.header * LINEHEIGHT_SCALING_FACTOR
    }
}