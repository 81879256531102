import React, { useState, useEffect } from 'react';
import { Container, Timer, WaveForm } from './index.style';

const VoiceMemoDisplay = ({ startTime, isRecording }) => {
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        let timer;
        if (isRecording) {
            timer = setInterval(() => {
                setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
            }, 1000);
        } else if (startTime) {
            setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [startTime, isRecording]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <Container>
            <Timer>{formatTime(elapsedTime)}</Timer>
            <WaveForm isAnimating={isRecording} />
        </Container>
    );
};

export default VoiceMemoDisplay;