import React, { useState, useEffect } from 'react';
import CustomButton from '../CustomButton';
import * as api from '../../utils/api';

const AnnotationRecordButton = ({ onRecordingComplete, selectedLanguage, selectedComponent }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        const mimeType = 'audio/webm;codecs=opus';
                const newMediaRecorder = new MediaRecorder(stream, {mimeType});
        setMediaRecorder(newMediaRecorder);

        newMediaRecorder.ondataavailable = e => {
          const audioData = e.data;
          const audioSrc = URL.createObjectURL(audioData);
          transcribeAudio(audioSrc);
        };
      });
    }
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      // Only trigger if 'r' is pressed and not in a text input/textarea
      if (event.key.toLowerCase() === 'r' && 
          !['input', 'textarea'].includes(document.activeElement.tagName.toLowerCase()) &&
          !isTranscribing) {
        startRecording();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isRecording, isTranscribing, mediaRecorder]); 

  const startRecording = () => {
    if (mediaRecorder && !isTranscribing) {
      if (!isRecording) {
        setIsRecording(true);
        mediaRecorder.start();
      } else {
        setIsRecording(false);
        mediaRecorder.stop();
        setIsTranscribing(true);
      }
    }
  };

  const transcribeAudio = (audioSrc) => {
    setIsTranscribing(true);
        api.transcribeAudio(audioSrc, selectedLanguage, selectedComponent).then(r => {
        const transcribedText = r['unprocessed_comments'];
        onRecordingComplete(transcribedText);
        setIsTranscribing(false);
        }).catch(error => {
        console.error('Transcription error:', error);
        setIsTranscribing(false);
      });
  };

  return (
    <CustomButton
      text={isRecording ? 'Stop recording' : (isTranscribing ? 'Transcribing...' : 'Record answer')}
      iconName={isRecording ? 'FaRegStopCircle' : 'FaMicrophone'}
      isRecording={isRecording}
      disabled={isTranscribing}
      onClick={startRecording}
    />
  );
};

export default AnnotationRecordButton;