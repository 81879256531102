import styled from 'styled-components';

export const GuidanceOverlay = styled.div`
    position: absolute;
    top: 0; 
    right: 0;
    /* Adjust the width to account for the margin on the LeftSide */
    width: calc(50% - 10px); 
    height: 100%; 
    background-color: white;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
    border-radius: 8px;
    z-index: 1000;
    overflow-y: auto;
    box-sizing: border-box;
`;


export const GuidanceContent = styled.div`
  position: relative; /* Necessary for absolute positioning of the button */
  padding: 15px;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const Title = styled.h2`
  margin-bottom: 15px;
`;

export const GuidanceList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const GuidanceItem = styled.li`
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
  
  &:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #4a4a4a;
  }
`;
