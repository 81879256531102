import { ContainerDiv } from "./index.style";

function Container(props) {
    return (
        <ContainerDiv id="container">
            {props.children}
        </ContainerDiv>
    );
}

export default Container;