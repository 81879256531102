import React, { createContext, useState, useContext } from 'react';

const StepsContext = createContext();

const initialStepData = {
  unprocessedComments: '',
  processedComments: '',
  parts: [],
  annotations: []
};

export const StepsProvider = ({ children }) => {
  const [stepsData, setStepsData] = useState({});

  const updateStepData = (step, newData) => {
    setStepsData(prevData => ({
      ...prevData,
      [step]: { ...(prevData[step] || initialStepData), ...newData }
    }));
  };

  const getStepData = (step) => {
    if (!stepsData[step]) {
      // Initialize data for this step if it doesn't exist
      setStepsData(prevData => ({
        ...prevData,
        [step]: { ...initialStepData }
      }));
      return initialStepData;
    }
    return stepsData[step];
  };

  return (
    <StepsContext.Provider value={{ stepsData, updateStepData, getStepData }}>
      {children}
    </StepsContext.Provider>
  );
};

export const useStepsContext = () => useContext(StepsContext);