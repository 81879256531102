import React, { useState, useEffect } from 'react';
import { EditorOverlay, EditorContent, Title, Timestamp, ButtonContainer} from './index.style';
import CustomButton from '../CustomButton';
import CustomTextArea from '../CustomTextArea';

const TranscriptEditor = ({ transcript, onSave, onClose }) => {
  const [editedText, setEditedText] = useState(transcript.text);

  useEffect(() => {
    setEditedText(transcript.text);
  }, [transcript]);

  const handleSave = () => {
    onSave({ ...transcript, text: editedText });
    onClose();
  };

  return (
    <EditorOverlay>
      <EditorContent>
        <Title>Edit Note</Title>
        <Timestamp>Timestamp: {transcript.timestamp}</Timestamp>
        <CustomTextArea
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          style={{ width: '100%', height: '200px', marginBottom: '10px' }}
        />
        <ButtonContainer>
          <CustomButton style={{marginLeft: 10}} text="Cancel"  onClick={onClose} />
          <CustomButton style={{marginLeft: 10}} text="Save" onClick={handleSave} />
        </ButtonContainer>
      </EditorContent>
    </EditorOverlay>
  );
};

export default TranscriptEditor;
