import React, { useState } from 'react';
import { DropZoneWrapper, UploadButton } from './index.style';
import { MdFileUpload } from "react-icons/md";
import * as api from '../../utils/api';

function DropZone() {
  const [csvFile, setCsvFile] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    
    if (!file) {
      alert('Please select a file');
      return;
    }

    if (file.type !== 'text/csv') {
      alert('Please upload a valid CSV file');
      return;
    }

    setCsvFile(file);
    
    try {
      const response = await api.uploadCSV(file);
      alert('CSV file uploaded successfully!');
      
      // Reload after successful upload
      window.location.reload();
      
    } catch (error) {
      alert(error.message || 'Failed to upload CSV');
    }
  };

  return (
    <DropZoneWrapper>
      <UploadButton>
        <MdFileUpload style={{ marginRight: '6px', fontSize: '16px' }} />
        <label htmlFor="file-upload">
          {'Upload Component'}
        </label>
        <input
          id="file-upload"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </UploadButton>
    </DropZoneWrapper>
  );
}

export default DropZone;
